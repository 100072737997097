import '../utils-hoist/debug-build.js';
import '../utils-hoist/logger.js';
import '../utils-hoist/time.js';
import '../utils-hoist/syncpromise.js';
import '../debug-build.js';
import { createOtelLogEnvelope } from './envelope.js';
const CLIENT_TO_LOG_BUFFER_MAP = new WeakMap();

/**
 * Flushes the logs buffer to Sentry.
 *
 * @param client - A client.
 * @param maybeLogBuffer - A log buffer. Uses the log buffer for the given client if not provided.
 */
function _INTERNAL_flushLogsBuffer(client, maybeLogBuffer) {
  const logBuffer = maybeLogBuffer ?? CLIENT_TO_LOG_BUFFER_MAP.get(client) ?? [];
  if (logBuffer.length === 0) {
    return;
  }
  const clientOptions = client.getOptions();
  const envelope = createOtelLogEnvelope(logBuffer, clientOptions._metadata, clientOptions.tunnel, client.getDsn());

  // Clear the log buffer after envelopes have been constructed.
  logBuffer.length = 0;

  // sendEnvelope should not throw
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  client.sendEnvelope(envelope);
}
export { _INTERNAL_flushLogsBuffer };
